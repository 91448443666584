import React, {Component} from 'react'
import './styles.css'
import photokrate from "./tobykrateheadshot18lowres.jpg";

import cog1 from "./assets/cog1.png"
import cog2 from "./assets/cog2.png"
import petct from "./assets/petct.png"
import zzzome1 from "./assets/zzzome1.png"
import zzzome2 from "./assets/zzzome2.png"
import elcap from "./assets/elcap.png"

class Gallery extends Component {
    render(){
        return(
            <div className="galleryDiv">
                <button className="backButton" onClick={()=> this.props.history.push("")}>←</button>
                <h1 className="galleryTitles">Welcome to my gallery</h1>
                <p className="galleryTitles">Here you will find an assortment of works that I have created over the years. You will also find photos of me for modelling contracts.</p>

                <div className="galleryBlock" style={{flexDirection:"row"}}>
                    <img src={photokrate} className="galleryImage" alt="image of toby"/>
                    <div className="galleryPlaque">
                        <h2>Foreword</h2>
                        <p>You know how sometimes you are talking about something and are asked to present proof because it sounds so outlandish and you spend the next 5 minutes searching your phone for a picture? Well, this gallery has a collection of fun projects I worked on but also some interesting projects that I keep here for rapid retrieval.</p>
                    </div>
                </div>

                <div className="galleryBlock" style={{flexDirection:"row-reverse"}}>
                    <img src={zzzome1} className="galleryImage"/>
                    <div className="galleryPlaque">
                        <h2>The Zzzome</h2>
                        <p>Sound isolation dome and personal movie theatre. I made the Zzzome during my pharmacy school days. All-nighters, loud roommates, and the desire to take naps pushed me to create this isolation dome. 6 mid-range speakers, 2 subwoofers, 1 microphone arranged around 8-axes, the Zzzome also had 7.1 channel surround sound in mind. Due to heat from the electronics, I ended up installing a fan in the back for air circulation. The Zzzome frame was 3D printed, screen and speakers were from gutted electronics. The input was initially a Raspberry Pi 4B but later switched to a Windows laptop because of lack of driver support for surround sound.</p>
                    </div>
                </div>

                <div className="galleryBlock" style={{flexDirection:"row"}}>
                    <img src={cog2} className="galleryImage"/>
                    <div className="galleryPlaque">
                        <h2>Clock computer</h2>
                        <p>One of my pandemic projects. Inspired by Disney's <em>Beauty and the Beast</em> character, Cogsworth. Unfortunately Cogsworth's arms fell off during a move. This project is on an indefinite pause because it is hard to source certain hardware during the supply chain shortage (a GPU)</p>
                    </div>
                </div>

                <div className="galleryBlock" style={{flexDirection:"row-reverse"}}>
                    <img src={petct} className="galleryImage"/>
                    <div className="galleryPlaque">
                        <h2>PET vs CT/PET co-registered Tumor Contouring</h2>
                        <p>When contouring (defining the 3D space volume) tumors in mouse model breast cancer, CT/PET co-registered images improve human contouring accuracy when compared to using a PET only image.</p>
                    </div>
                </div>

                <div className="galleryBlock" style={{flexDirection:"row"}}>
                    <img src={elcap} className="galleryImage"/>
                    <div className="galleryPlaque">
                        <h2>El Capitan</h2>
                        <p>Borderline spur of the moment hike to summit El Capitan (Yosemite National Park). It was coming back down that was challenging: darkness, irrational fear of bears, no flashlight, and barely any phone battery. Once at the top, I think my phone (iPhone X) only had ~20% left with zero in a battery bank. From the photo, you can see it's sunset (7:03pm). The descent would take us well into the night. The full story of this experience also includes a hectic (legal and safe) drive back to SFO to catch a flight.</p>
                    </div>
                </div>

            </div>
        )
    }
}
export default Gallery