import React, {Component} from 'react'
import './styles.css'

class Investments extends Component {
    render(){
        return(
            <div className="galleryDiv">
                <button className="backButton" onClick={()=> this.props.history.push("")}>←</button>
                <h1 className="galleryTitles">Investments</h1>
                <p className="galleryTitles">I obviously will not be putting my entire portfolio on this page. The companies and assets here are listed because I have a strong conviction in them, and/or I vouch for them often enough that I want to make my disclosure apparent.</p>

                <p className="galleryTitles" style={{padding:15}}>NASDAQ:AAPL</p>
                <p className="galleryTitles" style={{padding:15}}>NYSE:DIS</p>
                <p className="galleryTitles" style={{padding:15}}>NASDAQ:TSLA</p>
                <p className="galleryTitles" style={{padding:15}}>Bayshore Healthcare LTD.</p>
                <p className="galleryTitles" style={{padding:15}}>Turo (NASDAQ:IAC)</p>

                <p className="galleryTitles" style={{padding:15}}>This page is not to serve as investment advice. I attest to holding shares of these companies.</p>
            </div>
        )
    }
}
export default Investments