import React, {Component} from 'react'
import './styles.css'
import photo from "./article_images/supplementsAndWhatNot.jpg";


class rphMLM extends Component {
    render(){
        return(
            <div className="galleryDiv">
                <button className="backButton" onClick={()=> this.props.history.push("/gallery")}>←</button>

                <h1 className="articleTextTitle">Why pharmacists should NOT join MLMs</h1>



                <img className="articleImageCenter" src={photo} style={{height:"auto",maxWidth:"900px",width:"80%",padding:"10px"}} alt="supplements" />

                <p className="articleText">First off, this is an opinion piece. Although I have reservations against all MLMs, this article is targeted at pharmacists who join or want to join MLMs that promote health products and make health claims. For those who do not know, an MLM is a “multi-level marketing” company. There are many articles about them online, so I encourage you to look them up if you haven’t already. Essentially, all MLM’s have a pyramid-like organization structure, where the people at the top get a fraction of their downstream.</p>
                <p className="articleText">Why am I writing this now? Most of this article will be from a Canadian context, but I am sure pharmacist wages and hours are declining elsewhere, too, making MLMs ever more attractive to pharmacists to get a paycheck. From anecdotal reports, some pharmacists are even being “offered” MLM opportunities, with the selling point being that it’s a great use of their education.</p>
                <p className="articleText">Many who know me know I am quite curious and tend to get caught in some very weird businesses and investments. For the most part, this works out for me, but somewhat rarely, I get myself stuck in an MLM presentation. Because I do not want to get sued (MLMs have A LOT of money), I will not mention which MLM “business owners” I have had the unfortunate pleasure of interacting with.</p>
                <p className="articleText">This list now goes over my top three reasons why no pharmacist should join a health-focused MLM:</p>

                <h3 className="articleText">1. Remember efficacy and safety?</h3>
                <p className="articleText">Part of making money in an MLM is selling to customers (the other part is recruiting other unfortunate people to join your pyramid organization). Part of the reason why MLMs work is through deceptive claims about the efficacy of their products. When you became a pharmacist, you took an oath to protect the best interests of your patients. Many of the natural health products and supplements sold by MLMs do have potential research suggesting some efficacy, but the marketing is always overblown. CLA (Conjugated linoleic acid) is a popular one. It is added to many weight loss MLM products and heck, you can find it on the shelves of most pharmacies! Yes, there is a body of evidence that indicates CLA may have some benefit in weight loss, but how much of this evidence is placebo-controlled? How much is in animal models? If you are recruiting or selling on behalf of an MLM, are you able to explain the efficacy and safety of a particular product the same way you do if you were approached in your pharmacy? If yes, I applaud you, but you will not make the type of money that your upstream told you about.</p>
                <p className="articleText">Sliding over to safety, pharmacists should also warn customers if a product is associated with hypersensitivity reactions and potential organ damage. Certain oils, sprays, not just supplements! </p>
                <p className="articleText">Therefore, to ethically participate in a health-based MLM as a pharmacist, be ready to undersell your product. </p>

                <h3 className="articleText">2. What about cost?</h3>
                <p className="articleText">Ok, so let’s say the product that you are selling does is safe and efficacious. How much does it cost? Is it cheaper at the neighbourhood three-letter? MLM products are notoriously marked up. Sometimes your customer is desperate to fix their ailment, and your product contains the ingredient they are convinced on. The morally correct thing to do is let the customer know there is a cheaper option for the same ingredient elsewhere. </p>
                <p className="articleText">Some MLMs will argue that the ordering convenience and customer support justify the increased cost, I mostly agree. But if the cost is nearly double and the customer can receive better advice from the nearby pharmacy, I don’t think you as a pharmacist should support such an idea. In the US and Canada, that’s why generic substitution laws promote the use of generics to help patients save on cost! </p>

                <h3 className="articleText">3. You don’t need to be the one selling to cause harm</h3>
                <p className="articleText">Believe it or not, your upstream will use the fact you are a pharmacist to recruit new people. During my experiences, they will name drop downstream nurses and pharmacists as an endorsement to their product and business model. Healthcare professionals always bring a flair of legitimacy for these operations. Most people who join MLMs do not make money and end up wasting their time.</p>
                <p className="articleText">The real kicker is when one of your MLM colleagues uses your title as a pharmacist to validate the safety and efficacy of a product to their customers. “[Your name] is a pharmacist, and they regularly sell ______ to lose weight.” When these products fail to achieve their magical promises, it will be you who looks extra bad. If that customer experiences harm, your presence would have indirectly allowed it to happen.</p>

                <h3 className="articleText">Conclusion</h3>
                <p className="articleText">Those are my three main points, but I have many more: encouraging polypharmacy, ruining your profession’s image are two other big ones that I think you can imagine after reading my article.</p>
                <p className="articleText">-January 25, 2021</p>
                <p className="articleText">This is an opinion piece written for the purpose of stimulating thought. I do not hold any financial positions in MLMs or their competitors. This article’s opinions are solely my own and are not meant to attack or defend any particular individual or group. If you have any questions, concerns, or would like to report a typo, please email me@tobyxia.com</p>


            </div>
        )
    }
}
export default rphMLM