import React, { useEffect, useState } from 'react';

import { motion } from "framer-motion"

import './Styles.css';

const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30
};

const Home = (props) => {
    useEffect(() => {
        document.title = "Toby Xia"
    })
    const [isOn, setIsOn] = useState(false);

    const onCapsule = () => setIsOn(!isOn);


    return(
        <div>
            <div style={{
                backgroundColor: 'white',
                margin:"0px",
                height:"70vh",
            }}>
                <div style={{display:"flex",justifyContent:"center"}}>
                    <h1>Toby Xia</h1>
                </div>
                <div style={{display:"flex",justifyContent:"center",padding:"10px",textAlign:"center"}}>
                    <p>PharmD, developer, founder and self-proclaimed coffee and tea drinking expert</p>
                </div>
                <div style={{display:"flex",justifyContent:"center",padding:"10px",textAlign:"center"}}>
                    <h3>A website to get to know me!</h3>
                </div>
                <div className="tutorial" data-isOn={isOn} style={{display:"flex",justifyContent:"center",padding:"2px",textAlign:"center"}}>
                    <p>(tap the capsule)</p>
                </div>
                <div className="mythicalWhiteSpace"> {/*data-isOn={isOn}>*/}

                </div>
                <div style={{display:"flex", justifyContent:"center"}}>
                    <div className="capLeftSpace" data-isOn={isOn} onClick={onCapsule}>
                        <motion.div className="capLeft" layout transition={spring} />
                    </div>

                    <div className="pellet" data-isOn={isOn}>
                        <button className="pelletButton"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href='https://linkedin.com/in/thxia';
                                }}>LinkedIn</button>
                    </div>
                    <div className="pellet" data-isOn={isOn}>
                        <button className="pelletButton" onClick={()=>props.history.push("/gallery")}>Gallery</button>
                    </div>
                    <div className="pellet" data-isOn={isOn}>
                        <button className="pelletButton" onClick={()=>props.history.push("/investments")}>Investments</button>
                    </div>
                    <div className="pellet" data-isOn={isOn}>
                        <button className="pelletButton" onClick={()=>props.history.push("/contact")}>Contact</button>
                    </div>


                    <div className="capRightSpace" data-isOn={isOn} onClick={onCapsule}>
                        <motion.div className="capRight" layout transition={spring} />
                    </div>


                </div>




            </div>

        </div>


    );

}

export default Home