import React, {Component} from 'react'
import './styles.css'
import photo from './TOBYXIA.jpg'

class Contact extends Component {
    render(){
        return(
            <div className="galleryDiv">
                <button className="backButton" onClick={()=> this.props.history.push("")}>←</button>
                <h1 className="galleryTitles">Contact</h1>
                <p className="galleryTitles">I am always open for a good chat!</p>

                <p className="galleryTitles" style={{padding:50}}>me@tobyxia.com</p>

                <img className="imageCenter" src={photo} style={{height:"250px",width:"auto",padding:"10px"}} alt="image of toby" />

            </div>
        )
    }
}
export default Contact