import React, {Component} from 'react'
import './styles.css'
import photo from './tobyAtBahen.JPG'

class About extends Component {
    render(){
        return(
            <div className="galleryDiv">
                <button className="backButton" onClick={()=> this.props.history.push("")}>←</button>
                <h1 className="galleryTitles">About</h1>

                <p className="aboutText">Toby Xia is a Chinese-Canadian entrepreneur and investor. An early adopter of cryptocurrency and amateur tea connoisseur. Ok, that last point probably was an oxymoron...</p>
                <h2 className="galleryTitles">Education</h2>
                <p className="aboutText">I am currently working toward a PharmD at the University of Toronto. Prior to my pharmacy education I completed about 66 credit hours specializing in Pharmacology and Biomedical Toxicology at UofT.</p>
                <img className="imageCenter" src={photo} style={{height:"250px",width:"auto",padding:"10px"}} alt="toby at Bahen Centre" />
                <p className="aboutText"><i>"Bahen Centre, 2019 - Apparently I have very little photos of myself at UofT that aren't of me eating food..."</i></p>
                <h2 className="galleryTitles">Education continued</h2>
                <p className="aboutText">People who know me well always ask "Why pharmacy?" I use to have a coherent answer, but I do not anymore. All I know are these three points:</p>
                <p className="aboutText">- Providing safe and efficacious drugs to the public</p>
                <p className="aboutText">- Pharmacy school develops a novel way of thinking that is hard to develop on your own (at least for me)</p>
                <p className="aboutText">- No one told me there was going to be 7 courses per semester and I would be out nearly 200k</p>

                <h2 className="galleryTitles">Education continued...</h2>
                <p className="aboutText">For those who haven't noticed yet, this entire about page is about education. I know you didn't get here from my home page. The truth is, this page use to be prominent on my old website and it's indexed quite heavily by search engines. The problem is I don't want to discuss too much about myself because there are too many scammers and identity thieves.
                Regardless, thank you for taking the time to read about me! I am very open to chats and grabbing a coffee, please message me on LinkedIn or me@tobyxia.com</p>


            </div>
        )
    }
}
export default About