import React, {Component} from 'react'
import './styles.css'

class FourOfour extends Component {
    render(){
        return(
            <div className="galleryDiv">
                <button className="backButton" onClick={()=> this.props.history.push("")}>←</button>
                <h1 className="galleryTitles">404</h1>

                <p className="aboutText">Hmmm it looks like this page no longer exists or has moved</p>
                <p className="aboutText">Press the back button in the top left corner to be brought back to the home page</p>


            </div>
        )
    }
}
export default FourOfour