import React from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Home from './Home'
import Footer from './Footer'
import './App.css';
import Gallery from "./Gallery";
import Investments from "./Investments";
import Contact from "./Contact";
import About from "./About";
import FourOfour from "./FourOfour";
import rphMLM from "./Articles/rphMLM";

function App() {
  return (
      <Router basename={process.env.PUBLIC_URL}>
            <div className="App">
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/gallery" component={Gallery}/>
                    <Route exact path="/investments" component={Investments}/>
                    <Route exact path="/fav-links" component={Gallery}/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/about.php" component={About}/>
                    <Route exact path="/article/why-pharmacists-should-NOT-join-MLMs" component={rphMLM}/>
                    <Route component={FourOfour}/>
                    <Redirect to="/"/>
                </Switch>
                <Footer/>
            </div>

      </Router>
  );
}

export default App;
