import React, {Component} from 'react'
import './styles.css'

/*
This footer has nothing in it but it is rendered via the default container found in App.js
 */
class Footer extends Component {
    render(){
        return(
            <div className="footer-div">
                <h5 className="row">I value privacy, simplicity and cost; this site does not use cookies and does not track you</h5>
                <h5 className="row" style={{color:"gray"}}>Website made and designed by Toby Xia</h5>
                <h6 className="row">Copyright 2022 Toby Xia</h6>
            </div>
        )
    }
}
export default Footer